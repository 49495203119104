<template>
  <div>
    <b-card border-variant="primary" body-class="px-4 py-4" class="mt-10">
      <h4 class="font-weight-bold text-dark" v-b-toggle="'instructions-panel'">
        <i class="fa-solid fa-circle-info fa-md text-primary"></i>
        <span class="ml-2" style="vertical-align: center">Instructions</span>
      </h4>

      <label class="mt-3 font-weight-bold"
        ><span v-html="instructionsText"></span
      ></label>
    </b-card>
  </div>
</template>

<style lang="scss"></style>

<script>
export default {
  name: "Instructions",
  props: {
    instructionsText: String,
  },

  data() {
    return {};
  },

  methods: {},
};
</script>
